import React from "react"
import { useState } from "react"
import Logo from "../assets/icons/logo.svg"
import Menu from "../assets/icons/menu.svg"
import Close from "../assets/icons/close.svg"
import Instagram from "../assets/icons/instagram.svg"
import Linkedin from "../assets/icons/linkedin.svg"
import Facebook from "../assets/icons/facebook.svg"
import { useEffect } from "react"

const HeaderLink = props => (
  <a className="text-xl uppercase font-fontspring-bold text-azur" {...props}>
    {props.children}
  </a>
)

const MobileHeaderLink = props => (
  <a
    className="w-full pt-6 pb-3 text-4xl text-white uppercase border-b border-silver font-fontspring-bold"
    onClick={props.closeMenu}
    {...props}
  >
    {props.children}
  </a>
)

const MenuOpen = ({ closeMenu }) => (
  <div className="absolute top-0 left-0 min-w-full min-h-screen px-6 py-4 bg-azur">
    <div className="flex justify-between">
      <Logo className="text-white h-14" />
      <button onClick={closeMenu}>
        <Close className="w-10 h-10" />
      </button>
    </div>
    <div className="flex flex-col mx-8 mt-16">
      <MobileHeaderLink href="/#biography" closeMenu={closeMenu}>
        Bio
      </MobileHeaderLink>
      <MobileHeaderLink href="/#speeches" closeMenu={closeMenu}>
        Speeches
      </MobileHeaderLink>
      <MobileHeaderLink href="/videos">Videos</MobileHeaderLink>
      <MobileHeaderLink href="/carvingcamp">Carving Camp</MobileHeaderLink>
      <MobileHeaderLink href="/#contact" closeMenu={closeMenu}>
        Contact
      </MobileHeaderLink>
    </div>
    <div className="flex mx-8 mt-24">
      <a
        className="mr-9"
        href="https://www.instagram.com/nevingalmarini/"
        target="_blank"
      >
        <Instagram className="w-10 h-10" />
      </a>
      <a
        className="mr-9"
        href="https://www.linkedin.com/in/nevin-galmarini-b4396397?originalSubdomain=ch"
        target="_blank"
      >
        <Linkedin className="w-10 h-10" />
      </a>
      <a href="https://www.facebook.com/nevingalmarini/" target="_blank">
        <Facebook className="w-10 h-10" />
      </a>
    </div>
  </div>
)

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenu = () => {
    setIsMenuOpen(true)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <header className="sticky top-0 z-50 flex items-center justify-between px-6 py-4 bg-white shadow md:px-12">
      <a href="/">
        <Logo className="h-14" />
      </a>
      <div className="hidden space-x-12 md:flex">
        <HeaderLink href="/#biography">Bio</HeaderLink>
        <HeaderLink href="/#speeches">Speeches</HeaderLink>
        <HeaderLink href="/videos">Videos</HeaderLink>
        <HeaderLink href="/carvingcamp">Carving Camp</HeaderLink>
        <HeaderLink href="#contact">Contact</HeaderLink>
      </div>
      <div className="flex items-center md:hidden">
        {!isMenuOpen ? (
          <button onClick={openMenu}>
            <Menu className="w-8 h-8" />
          </button>
        ) : (
          <MenuOpen closeMenu={closeMenu} />
        )}
      </div>
    </header>
  )
}

export default Header
