import React from "react"
import Instagram from "../assets/icons/instagram.svg"
import Linkedin from "../assets/icons/linkedin.svg"
import Facebook from "../assets/icons/facebook.svg"

const Footer = () => {
  return (
    <footer>
      <div className="px-16 py-20 text-white bg-azur">
        <div className="">
          <h2 className="text-6xl uppercase font-fontspring-bold">
            How to get in touch:
          </h2>
          <div className="flex flex-col mt-20 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:grid-flow-col">
            <div>
              <h3 className="text-xl uppercase font-fontspring-bold">
                Social media
              </h3>
              <div className="flex mt-10 space-x-10 md:mt-16">
                <a
                  href="https://www.instagram.com/nevingalmarini/"
                  target="_blank"
                >
                  <Instagram className="w-11 h-11" />
                </a>
                <a
                  href="https://www.linkedin.com/in/nevin-galmarini-b4396397?originalSubdomain=ch"
                  target="_blank"
                >
                  <Linkedin className="w-11 h-11" />
                </a>
                <a
                  href="https://www.facebook.com/nevingalmarini/"
                  target="_blank"
                >
                  <Facebook className="w-11 h-11" />
                </a>
              </div>
            </div>
            <div>
              <h3
                className="text-xl uppercase font-fontspring-bold"
                id="contact"
              >
                E-Mail
              </h3>
              <div className="mt-10 md:mt-16">
                <a href="mailto:info@nevingalmarini.ch" className="underline">
                  info@nevingalmarini.ch
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-xl uppercase font-fontspring-bold">
                Autographs
              </h3>
              <p className="mt-10 md:mt-16">
                For personally signed autograph cards, please send a stamped
                reply envelope to: <br />
                <br />
                Team Galmarini <br />
                Fuschina 81
                <br /> 7546 Ardez
                <br /> Switzerland
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
