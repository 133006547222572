import React from "react"
import Section from "../assets/icons/section.svg"
import SectionMobile from "../assets/icons/section-mobile.svg"

const ImageContent = ({ image, children, className, id }) => {
  let style = {}

  if (image) {
    style = { backgroundImage: 'url("' + image + '")' }
  }

  return (
    <div
      style={style}
      className={[
        "bg-center font-fontspring bg-no-repeat bg-cover aspect-section md:aspect-auto md:h-[936px] relative",
        className,
      ].join(" ")}
    >
      <span className="invisible absolute top-[-88px] block" id={id}>
        {id}
      </span>
      <Section className="absolute hidden w-full md:p-8 md:inline-block" />
      <SectionMobile className="absolute w-full p-6 md:hidden" />
      {children}
    </div>
  )
}

export default ImageContent
